export const appRootTag = "app"

export const appRootId = "app"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"author","name":"author","content":"Pink Yellow"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"Glory"},{"hid":"application-name","name":"application-name","content":"Glory"},{"hid":"msapplication-TileColor","name":"msapplication-TileColor","content":"#970000"},{"hid":"theme-color","name":"theme-color","content":"#ffffff"}],"link":[{"rel":"preconnect","href":"https://glory.pinkyellow.network"},{"rel":"dns-prefetch","href":"https://glory.pinkyellow.network"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/icon/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/icon/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/icon/favicon-16x16.png"},{"rel":"manifest","href":"/icon/site.webmanifest"},{"rel":"mask-icon","href":"/icon/safari-pinned-tab.svg","color":"#000000"},{"rel":"shortcut icon","href":"/icon/favicon.ico"}],"style":[],"script":[{"innerHTML":"(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement(\"script\");r.src=o;r.async=true;i=t.getElementsByTagName(\"script\")[0];i.parentNode.insertBefore(r,i)})(window,document,\"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js\",\"vgo\");\n          vgo('setAccount', '255362459');\n          vgo('setTrackByDefault', true);\n          vgo('process');"}],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#app'